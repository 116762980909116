<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Report',

  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FormSection: () => import('@/components/general/FormSection'),
    ReportCard: () => import('./ReportCard')
  },

  data () {
    return {
      reportList: [],
      reports: [
        {
          groupTitle: this.$t('reports:header.section.financial'),
          groupItems: [
            { // 12
              permission: 'reports_financial',
              image: 'canvas.svg',
              title: this.$t('reports:report.financial.list.title'),
              description: this.$t('reports:report.financial.list.description'),
              url: {
                name: 'reports.financial'
              },

              module: 'ecommerce'
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.integrations'),
          groupItems: [
            { // 7
              permission: 'reports_rae_services',
              image: 'canvas.svg',
              title: this.$t('reports:report.rae.services.list.title'),
              description: this.$t('reports:report.rae.services.list.description'),
              url: {
                name: 'reports.rae.services'
              },

              module: 'rae'
            },

            { // 9
              permission: 'reports_sas_events',
              image: 'canvas.svg',
              title: this.$t('reports:report.sas.events.list.title'),
              description: this.$t('reports:report.sas.events.list.description'),
              url: {
                name: 'reports.sas.events'
              },

              module: 'sas'
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.surveys'),
          groupItems: [
            { // 2
              permission: 'reports_surveys_answers',
              image: 'survey.svg',
              title: this.$t('reports:report.surveys.list.title'),
              description: this.$t('reports:report.survey.answers.list.description'),
              url: {
                name: 'reports.surveys'
              }
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.programs'),
          groupItems: [
            { // 10
              permission: 'reports_programs_resume',
              image: 'learning-paths.svg',
              title: this.$t('reports:report.programs.resume.list.title'),
              description: this.$t('reports:report.programs.resume.list.description'),
              url: {
                name: 'reports.program.resume'
              },

              module: 'program'
            },

            { // 11
              permission: 'reports_enrollments_program',
              image: 'canvas.svg',
              title: this.$t('reports:report.enrollment.program.list.title'),
              description: this.$t('reports:report.enrollment.program.list.description'),
              url: {
                name: 'reports.enrollment.program.resume'
              },

              module: 'program'
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.solutions'),
          groupItems: [
            { // 1
              permission: 'reports_enrollments',
              image: 'enrollments.svg',
              title: this.$t('reports:report.enrollments.list.title'),
              description: this.$t('reports:report.enrollments.list.description'),
              url: {
                name: 'reports.enrollments'
              }
            },

            { // 5
              permission: 'reports_solutions_resume',
              image: 'canvas.svg',
              title: this.$t('reports:report.solutions.resume.list.title'),
              description: this.$t('reports:report.solutions.resume.list.description'),
              url: {
                name: 'reports.solutions.resume'
              }
            },

            { // 8
              permission: 'reports_solution_alerts',
              image: 'canvas.svg',
              title: this.$t('reports:report.solution.alerts.list.title'),
              description: this.$t('reports:report.solution.alerts.list.description'),
              url: {
                name: 'reports.solution.alerts'
              }
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.users'),
          groupItems: [
            { // 0
              permission: 'reports_students',
              image: 'students.svg',
              title: this.$t('reports:report.students.list.title'),
              description: this.$t('reports:report.students.list.description'),
              url: {
                name: 'reports.students'
              }
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.paths'),
          groupItems: [
            { // 4
              permission: 'reports_learning_path_resume',
              image: 'learning-paths.svg',
              title: this.$t('reports:report.learning.paths.resume.list.title'),
              description: this.$t('reports:report.learning.paths.resume.list.description'),
              url: {
                name: 'reports.learning.path.resume'
              }
            },

            { // 6
              permission: 'reports_enrollments_learning_path',
              image: 'canvas.svg',
              title: this.$t('reports:report.enrollment.learning.path.list.title'),
              description: this.$t('reports:report.enrollment.learning.path.list.description'),
              url: {
                name: 'reports.enrollment.learning.path.resume'
              }
            }
          ]
        },
        {
          groupTitle: this.$t('reports:header.section.comments'),
          groupItems: [
            {
              permission: 'reports_comments',
              image: 'canvas.svg',
              title: this.$t('reports:report.comments.list.title'),
              description: this.$t('reports:report.comments.list.description'),
              url: {
                name: 'reports.comments'
              },

              module: 'assessment'
            }
          ]
        },

        {
          groupTitle: this.$t('reports:header.section.events'),
          groupItems: [
            {
              permission: 'reports_events',
              image: 'canvas.svg',
              title: this.$t('reports:report.events.list.title'),
              description: this.$t('reports:report.events.list.description'),
              url: {
                name: 'reports.events'
              },

              module: 'event'
            }
          ]
        }
      ]
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ])
  },

  created () {
    this.reports.forEach((report) => {
      let shouldReportBeVisible = false
      // eslint-disable-next-line prefer-const
      let newGroupItems = []

      report.groupItems.forEach((groupItem) => {
        if (this.getContextPermission(groupItem.permission) !== null) {
          if (groupItem.module === undefined || this.activeModules.includes(groupItem.module)) {
            newGroupItems.push(groupItem)
          }

          if (!shouldReportBeVisible) {
            shouldReportBeVisible = true

            this.reportList.push({
              groupTitle: report.groupTitle,
              groupItems: []
            })
          }
        }
      })

      if (shouldReportBeVisible) {
        this.reportList[this.reportList.length - 1].groupItems = newGroupItems
      }
    })
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('reports:header.title')"
      :description="$t('reports:header.description')"
      background="/assets/images/themes/default/png/reports_banner.png"
    />
    <div class="center reports-content">
      <div class="reports-list">
        <template v-for="(reportGroup, reportGroupIndex) in reportList">
          <FormSection
            v-if="reportGroup.groupItems.length"
            :key="reportGroupIndex"
            :title="reportGroup.groupTitle"
            has-line
          >
            <ReportCard
              v-for="(reportGroupItem, reportGroupItemIndex) in reportGroup.groupItems"
              :key="reportGroupItemIndex"
              class="report-item"
              :image="reportGroupItem.image"
              :title="reportGroupItem.title"
              :description="reportGroupItem.description"
              @click="$router.push(reportGroupItem.url)"
            />
          </FormSection>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.reports-content {
  margin: 60px auto;
}

.reports-list {
  align-items: stretch;
  flex-wrap: wrap;
  display: flex;
}

.reports-list .form-section {
  margin: 64px 0 0;
  width: 100%;
}

.reports-list .form-section:first-child {
  margin-top: 0;
}

.report-item {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 8px;
}
</style>
